import { useEffect, useState } from "react";

import qs from "query-string";

type QueryParameters = {
	[key: string]: string | undefined;
};

const useCollectionProductsTabs = () => {
	const [queryParams, setQueryParams] = useState<QueryParameters>({});

	useEffect(() => {
		const handlePopState = () => {
			const searchParams = new URLSearchParams(window.location.search);
			const params: QueryParameters = {};
			searchParams.forEach((value, key) => {
				params[key] = value;
			});
			setQueryParams(params);
		};

		window.addEventListener("popstate", handlePopState);

		handlePopState();

		return () => {
			window.removeEventListener("popstate", handlePopState);
		};
	}, []);

	const buildAndPushUrl = (queryParameters: QueryParameters) => {
		const { scroll = false, ...updatedParams } = queryParameters;

		const newQueryParams = { ...queryParams, ...updatedParams };

		const newUrl = qs.stringifyUrl({ url: window.location.pathname, query: newQueryParams }, { skipNull: true });

		window.history.pushState(null, "", newUrl);

		if (scroll) {
			window.scrollTo(0, 0);
		}

		setQueryParams(newQueryParams);
	};

	const handleTabChange = (type: string | undefined) => {
		buildAndPushUrl({ type });
	};

	return { handleTabChange, queryParams };
};

export default useCollectionProductsTabs;
