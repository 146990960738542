import type { PropertyGroupResponse } from "@/data/__generated__/types/generated";
import type { SelectedFilters } from "@/types";

import { FILTERS_ORDERING } from "@/constants";

import FilterBlock from "./components/FilterBlock";
import useActions from "./hooks";

type FiltersAsideProps = {
	filters?: PropertyGroupResponse[];
	handleSelectFilter: (value: string, isChecked: boolean, title: string) => void;
	resetFilters: () => void;
	selectedFilters: SelectedFilters;
};

const FiltersAside: React.FC<FiltersAsideProps> = ({
	filters = [],
	handleSelectFilter,
	selectedFilters,
	resetFilters,
}) => {
	const { sortedFilters } = useActions({ filters, selectedFilters });

	return (
		<aside className="flex flex-col w-full max-w-[282px] overflow-y-auto rounded-3xl sticky top-6 bg-white p-6 h-[92vh] max-h-[960px] sm:hidden">
			<p className="font-medium text-2xl">Фильтры</p>
			<ul className="flex flex-col gap-[15px] my-6">
				{sortedFilters.map((filter) => {
					return (
						<FilterBlock
							key={filter.title}
							selectedFilters={selectedFilters}
							handleSelectFilter={handleSelectFilter}
							filter={filter}
							isDefaultExpanded={FILTERS_ORDERING[filter.title]?.defaultExpanded ?? false}
						/>
					);
				})}
			</ul>
			<div className="flex flex-col gap-2">
				<button
					type="button"
					aria-label="Очистить фильтры"
					onClick={resetFilters}
					className="h-12 border-2 border-mediumGray rounded-xl w-full font-medium text-lg hover:bg-lightGlass transition"
				>
					Очистить фильтры
				</button>
			</div>
		</aside>
	);
};

export default FiltersAside;
