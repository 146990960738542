import Image from "next/image";

import { cn } from "@/libs/utils";

type ListNotFoundProps = {
	className?: string;
};

const ListNotFound: React.FC<ListNotFoundProps> = ({ className }) => {
	return (
		<div className={cn("border-2 rounded-3xl w-full p-8 border-white flex justify-between items-center", className)}>
			<h2 className="text-2xl max-w-[250px] font-medium">Мы ничего не нашли, попробуйте еще раз</h2>
			<Image width={600} height={434} className="w-[300px]" alt="Not Found" src="/static/images/error_not_found.svg" />
		</div>
	);
};

export default ListNotFound;
