import type { TabsType } from "@/types";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import Spinner from "../ui/Spinner";

import { cn } from "@/libs/utils";
import { ThemeColor } from "@/types";

type ShowMoreButtonProps = {
	className?: string;
	hasNextPage?: boolean;
	isLoading?: boolean;
	listType?: TabsType;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({ className, isLoading, hasNextPage, ...restProps }) => {
	if (!hasNextPage) return null;
	return (
		<button
			className={cn(
				className,
				"w-full font-medium text-lg flex justify-center items-center h-12 border-2 border-mediumGray rounded-xl"
			)}
			{...restProps}
		>
			<span className="mr-2">
				{isLoading ? (
					"Загрузка..."
				) : (
					<div>
						<span>Показать еще</span>
					</div>
				)}
			</span>
			{isLoading ? <Spinner /> : <XPlitkaIcon name="arrowBottom" color={ThemeColor.accentGreen} size={24} />}
		</button>
	);
};

export default ShowMoreButton;
