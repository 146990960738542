"use client";

import type { OptionType } from "./hooks";

import { useEffect } from "react";

import { cn } from "@/libs/utils";

import useActions from "./hooks";

type TabsProps = {
	className?: string;
	onChange: (value: string) => void;
	options: OptionType[];
	value: string;
};

const Tabs: React.FC<TabsProps> = ({ onChange, options, value, className }) => {
	const { selectedIndex, handleKeyDown } = useActions({
		onTabChange: onChange,
		options,
		value,
	});

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [selectedIndex, options, onChange, handleKeyDown]);

	return (
		<div className={cn("flex w-full flex-col", className)}>
			<div className="w-full max-w-[320px] ml-auto h-12 flex justify-start items-center bg-white rounded-xl relative overflow-hidden p-1 sm:ml-0 sm:max-w-full">
				<div
					className="tab-highlight"
					style={{
						transform: `translateX(${selectedIndex * 100}%)`,
						width: `${100 / options.length}%`,
					}}
				/>
				{options.map(({ title, id }) => (
					<button
						className={cn(
							"flex-1 h-full relative z-10 font-medium text-lg text-darkGray transition-colors delay-200",
							id === value && "text-white"
						)}
						key={title}
						type="button"
						onClick={() => onChange(id)}
					>
						{title}
					</button>
				))}
			</div>
			<div className="w-full block">{options.find(({ id }) => id === value)?.content}</div>
		</div>
	);
};

export default Tabs;
