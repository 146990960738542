import AddFavoriteButton from "../AddFavoriteButton";

import Skeleton from "./Skeleton";

const SkeletonCard: React.FC = () => {
	return (
		<div className="w-full rounded-3xl overflow-hidden bg-white h-[384px] sm:min-w-[220px]">
			<Skeleton className="w-full h-[192px] bg-mediumGray sm:w-[120px]">
				<AddFavoriteButton isFavorite={false} onClick={() => null} className="absolute top-0 right-0" />
			</Skeleton>
			<div className="px-4 flex items-center gap-2 mt-2">
				{Array.from({ length: 4 }, (_, index) => (
					<Skeleton key={index} className="animate-pulse bg-mediumGray w-7 h-7 rounded-lg border" />
				))}
			</div>
			<div className="p-4 mt-2">
				<Skeleton className="bg-mediumGray h-4 rounded-md w-[150px]" />
				<Skeleton className="bg-mediumGray h-2 rounded-md w-[200px] mt-3" />
				<Skeleton className="bg-mediumGray h-2 rounded-md w-[200px] mt-3" />
				<Skeleton className="bg-mediumGray h-5 rounded-md w-[150px] mt-[40px]" />
			</div>
		</div>
	);
};

export default SkeletonCard;
