export type OptionType = {
	content?: React.ReactNode;
	id: string;
	title: string;
};

interface TabsActionsArgs {
	onTabChange: (id: string) => void;
	options: OptionType[];
	value: string;
}

const TabsActions = ({ onTabChange, options, value }: TabsActionsArgs) => {
	const selectedIndex = options.findIndex(({ id }) => id === value);

	const getNewIndex = (key: string, currentIndex: number, maxLength: number): number => {
		if (key === "ArrowRight") return Math.min(currentIndex + 1, maxLength - 1);
		if (key === "ArrowLeft") return Math.max(currentIndex - 1, 0);
		return currentIndex;
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
			const newIndex = getNewIndex(event.key, selectedIndex, options.length);
			onTabChange(options[newIndex]!.id);
		}
	};

	return { selectedIndex, handleKeyDown, getNewIndex };
};

export default TabsActions;
