type SpinnerProps = {
	color?: string;
};

const Spinner: React.FC<SpinnerProps> = ({ color = "accentGreen" }) => {
	return (
		<div aria-label="Loading...">
			<svg
				width="24"
				height="24"
				fill="none"
				stroke="currentColor"
				strokeWidth="1.5"
				viewBox="0 0 24 24"
				className={`animate-spin w-6 h-6 stroke-${color}`}
			>
				<title>Loading...</title>
				<path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12" />
			</svg>
		</div>
	);
};

export default Spinner;
