import { cn } from "@/libs/utils";

type SkeletonProps = {
	children?: React.ReactNode | null;
	className?: string;
};

const SHIMMER_COLOR = "#ffffff";

const hexToRgb = (hex: string) => `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`;

const Skeleton: React.FC<SkeletonProps> = ({ className = "", children = null }) => {
	const rgb = hexToRgb(SHIMMER_COLOR);
	const shimmerStyle = {
		backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
	};

	return (
		<div className={cn("relative overflow-hidden bg-mediumGray", className)}>
			<div className="shimmer absolute top-0 right-0 bottom-0 left-0" style={shimmerStyle} />

			{children}
		</div>
	);
};

export default Skeleton;
