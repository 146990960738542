import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { ThemeColor } from "@/types";

import Skeleton from "./Skeleton";

const FiltersAsideSkeleton: React.FC = () => {
	return (
		<div className="w-full h-[760px] bg-white top-0 left-0 max-w-[282px] rounded-3xl p-6 sm:hidden">
			<Skeleton className="h-6 mt-1 w-[100px] rounded-md" />
			<div>
				<div className="flex items-center justify-between w-full">
					<Skeleton className="w-[40px] h-5 rounded-md mt-8" />
					<XPlitkaIcon name={"arrowSmallBottom"} color={ThemeColor.accentGreen} size={24} className="relative top-4" />
				</div>
				<div className="flex flex-col gap-2 mt-3">
					{Array.from({ length: 5 }, (_, index) => (
						<div key={index} className="flex items-center gap-2">
							<Skeleton className="h-6 w-6 rounded-md" />
							<Skeleton className="h-3 w-[60px] rounded-md" />
						</div>
					))}
					<Skeleton className="h-4 w-[130px] rounded-md mt-1" />
				</div>
			</div>
			<div>
				<div className="flex items-center justify-between w-full">
					<Skeleton className="w-[70px] h-5 rounded-md mt-6" />
					<XPlitkaIcon name={"arrowSmallBottom"} color={ThemeColor.accentGreen} size={24} className="relative top-3" />
				</div>
				<div className="flex flex-col gap-2 mt-3">
					{Array.from({ length: 5 }, (_, index) => (
						<div key={index} className="flex items-center gap-2">
							<Skeleton className="h-6 w-6 rounded-md" />
							<Skeleton className="h-3 w-[60px] rounded-md" />
						</div>
					))}
					<Skeleton className="h-4 w-[130px] rounded-md mt-1" />
				</div>
			</div>
			<div className="mt-5 flex flex-col gap-5">
				{Array.from({ length: 3 }, (_, index) => (
					<div key={index} className="flex items-center justify-between">
						<Skeleton className="h-4 w-[60px] rounded-md" />
						<XPlitkaIcon name="arrowSmallLeft" color={ThemeColor.primaryBlack} size={24} />
					</div>
				))}
			</div>
			<button
				className="h-12 border-2 mt-6 border-mediumGray rounded-xl w-full font-medium text-lg hover:bg-lightGlass transition"
				type="button"
				aria-label="Очистить фильтры"
			>
				Очистить фильтры
			</button>
		</div>
	);
};

export default FiltersAsideSkeleton;
