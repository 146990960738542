/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import type { SelectedFilters } from "@/types";

import { useEffect, useState } from "react";

import { usePathname, useRouter } from "next/navigation";
import qs from "query-string";

import { determineCategory } from "@/helpers";

function useFilterQueryParams(
	initialFilters: SelectedFilters
): [SelectedFilters, SelectedFilters | null, (value: string, isChecked: boolean, title: string) => void, () => void] {
	const router = useRouter();
	const pathName = usePathname();

	const [filters, setFilters] = useState<SelectedFilters>(initialFilters);
	const [selectedFilters, setSelectedFilters] = useState<SelectedFilters | null>(null);

	useEffect(() => {
		const queryParams = qs.parse(location.search);
		const updatedFilters = { ...initialFilters };

		Object.keys(initialFilters).forEach((key) => {
			const filterKey = key as keyof SelectedFilters;
			const filterValues = queryParams[filterKey];
			if (filterValues) {
				updatedFilters[filterKey] = Array.isArray(filterValues)
					? filterValues.filter((v): v is string => v !== null)
					: [filterValues].filter((v): v is string => v !== null);
			}
		});

		setFilters(updatedFilters);
	}, [pathName]);

	useEffect(() => {
		const nonEmptyFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value.length > 0));

		if (Object.keys(nonEmptyFilters).length > 0) {
			setSelectedFilters(nonEmptyFilters);
		} else {
			setSelectedFilters(null);
		}
	}, [filters]);

	const updateFilter = (value: string, isChecked: boolean, title: string) => {
		const category = determineCategory(title);
		setFilters((prevSelected) => {
			const currentFilters = prevSelected ?? initialFilters;

			const updatedCategory = isChecked
				? [...currentFilters[category]!, value]
				: currentFilters[category]!.filter((id) => id !== value);

			return {
				...currentFilters,
				[category]: updatedCategory,
			};
		});
	};

	useEffect(() => {
		const existingParams = qs.parse(location.search);
		const { page, type, keyword } = existingParams;
		const activeFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value.length > 0));

		const filterQueryString = qs.stringify(activeFilters, {
			arrayFormat: "comma",
		});
		const mergedQueryString = qs.stringifyUrl(
			{
				url: "",
				query: { ...{ page, type, keyword }, ...qs.parse(filterQueryString) },
			},
			{ skipNull: true, skipEmptyString: true }
		);

		router.push(`${pathName}${mergedQueryString}`, { scroll: false });
	}, [filters, router]);

	const resetFilters = () => {
		setFilters(initialFilters);
	};

	return [filters, selectedFilters, updateFilter, resetFilters];
}

export default useFilterQueryParams;
