import type { PropertyGroupResponse } from "@/data/__generated__/types/generated";
import type { SelectedFilters } from "@/types";

import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { FILTERS_DEFAULT_COUNT } from "@/constants";
import { determineCategory } from "@/helpers";
import Checkbox from "@/presentation/components/ui/Checkbox";
import { ThemeColor } from "@/types";

type FilterBlockProps = {
	filter: PropertyGroupResponse;
	handleSelectFilter: (value: string, isChecked: boolean, title: string) => void;
	isDefaultExpanded: boolean;
	selectedFilters: SelectedFilters;
};

const parseDimensions = (size: string): { height: number; width: number } => {
	const [width = "0", height = "0"] = size.split("x");
	return {
		width: Number(width) || 0,
		height: Number(height) || 0,
	};
};

const FilterBlock: React.FC<FilterBlockProps> = ({
	filter,
	handleSelectFilter,
	isDefaultExpanded,
	selectedFilters,
}) => {
	const categoryKey = determineCategory(filter.title);

	const sortedValues = useMemo(
		() =>
			[...filter.values].sort((a, b) => {
				const aChecked = selectedFilters[categoryKey]?.includes(a.slug);
				const bChecked = selectedFilters[categoryKey]?.includes(b.slug);

				if (aChecked !== bChecked) {
					return aChecked ? -1 : 1;
				}

				if (filter.title === "Бренд") {
					return a.title.localeCompare(b.title);
				}

				if (filter.title === "Размер") {
					const aDimensions = parseDimensions(a.title);
					const bDimensions = parseDimensions(b.title);

					if (aDimensions.width === bDimensions.width) {
						return aDimensions.height - bDimensions.height;
					}

					return aDimensions.width - bDimensions.width;
				}

				if (filter.title === "Цвет") {
					return a.title.localeCompare(b.title, "ru", { sensitivity: "base" });
				}

				return 0;
			}),
		[filter.values, selectedFilters, categoryKey, isDefaultExpanded]
	);

	const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
	const [filtersCount, setFiltersCount] = useState(FILTERS_DEFAULT_COUNT);

	const allFiltersCount = filter?.values?.length;
	const isOpened = filtersCount === allFiltersCount;

	const toggleOpenFilters = useCallback(() => {
		setFiltersCount(isOpened ? FILTERS_DEFAULT_COUNT : allFiltersCount);
	}, [isOpened, allFiltersCount]);

	const toggleExpand = useCallback(() => {
		setIsExpanded((prev) => !prev);
	}, []);

	useEffect(() => {
		const isAnyFilterSelected = filter.values.some((value) => selectedFilters[categoryKey]?.includes(value.slug));
		setIsExpanded(isAnyFilterSelected || isDefaultExpanded);
	}, [filter.values, selectedFilters, categoryKey, isDefaultExpanded]);

	if (!filter) return null;

	return (
		<li>
			<button
				onClick={toggleExpand}
				className="flex items-center justify-between font-medium w-full text-lg"
				type="button"
				aria-label="Фильтры"
			>
				<span>{filter.title}</span>
				<XPlitkaIcon
					name={isExpanded ? "arrowSmallBottom" : "arrowSmallLeft"}
					color={isExpanded ? ThemeColor.accentGreen : ThemeColor.primaryBlack}
					size={24}
				/>
			</button>
			{isExpanded && (
				<div className="mt-2">
					{sortedValues.slice(0, filtersCount).map((value) => {
						const isChecked = selectedFilters[categoryKey]!.includes(value.slug);

						return (
							<Checkbox
								key={`${filter.title}_${value.slug}`}
								id={value.slug}
								label={value.title}
								value={value.slug}
								checked={isChecked}
								onChange={({ target }) => handleSelectFilter(target.value, target.checked, filter.title)}
							/>
						);
					})}
					{sortedValues.length > FILTERS_DEFAULT_COUNT && (
						<button onClick={toggleOpenFilters} type="button" className="font-medium">
							{isOpened ? "Скрыть" : "Посмотреть все"}
						</button>
					)}
				</div>
			)}
		</li>
	);
};

export default memo(FilterBlock);
