import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import AddFavoriteButton from "../AddFavoriteButton";

import { ThemeColor } from "@/types";

import Skeleton from "./Skeleton";

const SkeletonProductCard: React.FC = () => {
	return (
		<div className="w-full rounded-3xl h-[509px] flex flex-col overflow-hidden bg-white">
			<Skeleton className="min-h-[192px] animate-pulse bg-mediumGray">
				<AddFavoriteButton isFavorite={false} onClick={() => null} className="absolute top-0 right-0" />
			</Skeleton>
			<div className="mt-4 px-4 h-full flex flex-col">
				<div className="flex-grow">
					<Skeleton className="animate-pulse bg-mediumGray h-4 rounded-md w-[70%]" />
					{Array.from({ length: 5 }, (_, index) => (
						<Skeleton key={index} className="animate-pulse bg-mediumGray h-3 rounded-md w-[200px] mt-3" />
					))}
				</div>
				<Skeleton className="animate-pulse bg-mediumGray h-6 rounded-full w-[90px] mt-3" />
				<div className="mt-auto pb-4">
					<Skeleton className="animate-pulse bg-mediumGray h-5 rounded-md w-[150px] mt-[30px] mb-4" />
					<Skeleton className="animate-pulse bg-mediumGray h-12 rounded-xl flex items-center justify-center gap-2">
						<span className="text-darkGray font-medium">Загрузка...</span>
						<XPlitkaIcon name="cart" size={24} color={ThemeColor.darkGray} />
					</Skeleton>
				</div>
			</div>
		</div>
	);
};

export default SkeletonProductCard;
