import type { PropertyGroupResponse } from "@/data/__generated__/types/generated";
import type { SelectedFilters } from "@/types";

import { FILTERS_ORDERING } from "@/constants";

type FiltersAsideActionsArgs = {
	filters: PropertyGroupResponse[];
	selectedFilters: SelectedFilters;
};

const FiltersAsideActions = ({ filters = [] }: FiltersAsideActionsArgs) => {
	const sortedFilters = filters
		? [...filters].sort((a, b) => {
				const priorityA = FILTERS_ORDERING[a.title]?.priority || Number.MAX_VALUE;
				const priorityB = FILTERS_ORDERING[b.title]?.priority || Number.MAX_VALUE;

				return priorityA - priorityB;
			})
		: [];

	return {
		sortedFilters,
	};
};

export default FiltersAsideActions;
